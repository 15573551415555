import Animate from 'ether-animate';
import Buttons from './Buttons';
import Contact from './Contact';
import Details from './Details';
import Gallery from 'ether-gallery';
import Lazy from 'ether-lazy';
import ServiceWorker from './ServiceWorker';
import SVG from './SVG';

function init()
{
	new Animate();
	new Buttons();
	new Contact();
	new Details();
	new Gallery();
	new Lazy();
	new ServiceWorker();
	new SVG();
}

init();
