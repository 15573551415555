export default class Details
{
	constructor()
	{
		this.toggles = document.querySelectorAll('.details button');
		this.toggles.length && this.initToggles();
	}

	initToggles()
	{
		this.toggles.forEach((toggle) => toggle.addEventListener('click', () => this.toggleDetails(toggle)));
	}

	toggleDetails(toggle)
	{
		const block = toggle.closest('.block');
		const btns = block.querySelectorAll('button');
		const details = block.querySelectorAll('.detail');
		const index = parseInt(toggle.dataset.i);

		btns.forEach((btn, i) =>
		{
			btn.classList.remove('bold');

			if(i === index)
				btn.classList.add('bold');
		});

		const classes = ['hide', 'tablet-hide', 'mobile-hide'];

		details.forEach((detail, i) =>
		{
			classes.map((className) => detail.classList.add(className));

			if(i === index)
				classes.map((className) => detail.classList.remove(className));
		});
	}
}
