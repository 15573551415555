export default class Buttons
{
	constructor()
	{
		this.initBtns();
	}

	initBtns()
	{
		this.btns = document.querySelectorAll('.btns > *');
		this.btns.forEach((btn) => btn.addEventListener('click', this.btnClicked.bind(this)));
	}

	btnClicked(e)
	{
		e.target.classList.add('active');
	}
}
