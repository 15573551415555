export default class Contact
{
	constructor()
	{
		this.contact = document.getElementById('contact');
		this.contact !== null && this.initContact();
	}

	initContact()
	{
		this.subject = this.contact.querySelector('#subject');
		this.toggles = this.contact.querySelectorAll('.subject-toggle');

		const applying = this.subject.querySelectorAll('option')[1].value;

		this.subject.addEventListener('change', () =>
		{
			if(this.subject.value === applying)
			{
				this.toggles.forEach((toggle) =>
				{
					const input = toggle.querySelector('input');

					input.value = '';
					input.required = true;

					toggle.style.display = 'block';
				});

				return;
			}

			this.toggles.forEach((toggle) =>
			{
				const input = toggle.querySelector('input');

				input.value = 'N/A';
				input.removeAttribute('required');

				toggle.style.display = 'none';
			});
		});
	}
}
