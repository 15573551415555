export default class ServiceWorker
{
	constructor()
	{
		if('serviceWorker' in navigator && navigator.serviceWorker && !window.devMode)
			this.initServiceWorker();
	}

	initServiceWorker()
	{
		let params = '';

		params += '?cssFilename=' + window.cssFilename;
		params += '&jsFilename=' + window.jsFilename;
		params += '&cacheHash=' + window.cacheHash;

		navigator.serviceWorker.register(window.baseUrl + 'service-worker.js' + params, {
			scope: '/'
		});
	}
}
