export default class SVG
{
	constructor()
	{
		this.svgs = document.querySelectorAll('.load-svg');
		this.svgs.length && this.loadSVGs();
	}

	loadSVGs()
	{
		this.svgs.forEach((svg) =>
		{
			fetch(svg.dataset.svg)
			.then(response => {
				return response.text();
			})
			.then(response => {
				svg.outerHTML = response;
			});
		});
	}
}
